.app-input-container {
	.app-input {
		@apply flex appearance-none justify-center items-center px-1 py-0 w-full bg-white outline-none text-gray-800;
		font-family: inter;
		-webkit-appearance: none;
		-moz-appearance: none;
		border-bottom: 1px solid #d0d5dd;
		height: 36px;
		transition: box-shadow 0.1s ease-in-out;

		&::-webkit-input-placeholder,
		&:-moz-placeholder,
		&::-moz-placeholder,
		&:-ms-input-placeholder {
			@apply text-gray-500;
			font-family: inter;
		}

		&:focus {
			@apply border-secondary;

			&::-webkit-input-placeholder,
			&:-moz-placeholder,
			&::-moz-placeholder,
			&:-ms-input-placeholder {
				color: transparent;
			}
		}

		&:disabled {
			@apply cursor-not-allowed bg-gray-300;
		}
	}

	.app-text-label {
		@apply invisible;
		font-family: inter;
		color: #667085;
		font-size: 12px;
	}

	&:focus-within {
		.app-text-label {
			@apply text-secondary visible;
		}
	}

	&.input-with-value {
		.app-text-label {
			@apply visible;
		}
	}
}

.app-dropdown {
	@apply w-full;

	&:disabled {
		@apply cursor-not-allowed bg-gray-300;
	}

	.app-dropdown-prefix {
		&__control {
			border-color: transparent;
			box-shadow: none;
		}

		&__value-container {
			@apply px-1 py-0;
		}

		&__control {
			@apply border-none w-full rounded-none;
			border-bottom: 1px solid #d0d5dd;
		}

		&__placeholder {
			@apply text-gray-400;
			font-family: inter;
		}
	}
}
