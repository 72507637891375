.app-header-item {
	@apply text-white;
	font-family: Gotham;
	font-size: 16px;
	font-weight: 350;
	line-height: 24px;
	letter-spacing: 0;
	text-align: left;
}

.app-button {
	@apply flex flex-row;
	box-sizing: border-box;
	justify-content: center;
	align-items: center;
	padding: 10px 18px;
	gap: 8px;
	min-width: 180px;
	height: 44px;
	background: #ffffff;
	border: 1px solid #d0d5dd;
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	flex: none;
	color: #344054;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	flex: none;
	flex-grow: 0;

	&.secondary {
		background: #2e4ff1;
		border: 1px solid #2e4ff1;
		color: white;
	}

	&.dark {
		background: #26357d;
		border: 1px solid #26357d;
		color: white;
	}

	&:disabled {
		@apply cursor-not-allowed;
		opacity: 0.7;
	}

	.spinner {
		animation-name: spin;
		animation-duration: 5000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}
}

.gothatm {
	font-family: 'Gotham';
}

.inter {
	font-family: 'Inter';
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

html {
	scroll-behavior: smooth;
}

.privacy-navigation {
	@apply flex flex-col mr-8 mt-6;
	min-width: 300px;
	max-width: 300px;

	p {
		@apply mb-1 cursor-pointer ml-4 text-gray-600;

		&:first-child {
			@apply ml-0 font-medium;
		}
	}
}

.page-privacy {
	@apply flex flex-col;

	@screen md {
		margin-left: 300px;
	}

	p {
		@apply mb-6 text-gray-600;
		font-family: 'Inter';
		font-size: 14px;

		&.title {
			@apply font-medium mt-4;
			font-size: 30px;
		}
	}
}
