@font-face {
	font-family: 'Gotham';
	font-style: normal;
	font-weight: 500;
	src: url('fonts/Gotham Medium.otf') format('opentype');
}

@font-face {
	font-family: 'Gotham-Medium';
	font-style: normal;
	font-weight: 500;
	src: url('fonts/Gotham Medium.otf') format('opentype');
}

@font-face {
	font-family: 'Proxima Nova-Regular';
	font-style: normal;
	font-weight: 400;
	src: url('fonts/Mark Simonson - Proxima Nova A.otf') format('opentype');
}

@font-face {
	font-family: 'Proxima Nova-Semibold';
	font-style: normal;
	font-weight: 400;
	src: url('fonts/.173.otf') format('opentype');
}
