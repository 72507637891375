.app-header {
	@apply bg-primary shadow-lg border-b-1;
	border-color: #2a3344;

	.desktop-container {
		@apply container mx-auto;
		.logo-container {
			@apply flex items-center py-4 px-2;
			img {
				@apply cursor-pointer mr-12;
			}
		}
	}

	.mobile-container {
		@apply flex flex-col absolute w-full bg-primary shadow-md transition-height duration-500 ease-in-out overflow-hidden;
		-prefix-animation: 'slide 1s ease 3.5s forwards';
		z-index: 100;

		&.closed {
			@apply h-0;
		}

		&.opened {
			height: calc(100vh - 100px);
		}
	}
}
